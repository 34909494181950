@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

* {
    margin: 0;
    padding: 0;
    font-family: "Montserrat", sans-serif;
    color: white;
}

.HomeMainCont {
    background-color: black;
    overflow-x: hidden;

}

.LandingContainer {
    padding-top: 180px;
    width: 100%;
    margin: auto;
    display: flex;
    height: 80vh;
    color: white;
    background-color: rgb(0, 0, 0);
    background-image: url('../images/LandingImg.png');
    background-size: cover;
}

span {
    background: -webkit-linear-gradient(#0b0b0b, #b7ff00, #ffff);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.LandingImg {
    width: 60%;
    margin-right: 75px;
}

.LandingImage {
    width: 100%;
    object-fit: cover;
    border-radius: 20px;
    padding-bottom: 40px;
}

.BHead h1 {
    padding-top: 15px;
}

.LandingText {
    width: 50%;
    margin-top: 10px;
    margin-left: 55px;
    margin-right: 0px;
    padding-top: 3%;
    padding-left: 100px;
    text-align: left;
}

.LandingText h2 {
    font-size: 50px;
    width: 550px;
}

.LandingText h3 {
    font-size: 40px;
    font-weight: 400;
}

.Underline {
    width: 270px;
    height: 5px;
    border-radius: 25px;
    background-color: rgb(166, 255, 0);
}

.LandingText h1 {
    font-size: 30px;
    padding-top: 25px;
    font-weight: 500
}

.Talk a {
    color: rgb(0, 0, 0);
    text-decoration: none;
    font-size: 18px;
}

.Talk {
    margin-top: 12px;
    width: 150px;
    background-color: rgb(166, 255, 0);
    color: rgb(0, 0, 0);
    border: none;
    border-radius: 5px;
    padding: 10px;
}

.Talk button {
    background-color: rgb(166, 255, 0);
}

.line {
    width: 200px;
    height: 5px;
    border-radius: 25px;
    margin-left: 40%;
    background-color: rgb(166, 255, 0);
}

.CoderImg {
    width: 100%;
    height: 500px;
    object-fit: fill;
}

.coder {
    width: 60%;
    height: 500px;
    margin: 20px;
    border-radius: 10px;
}

.BelowLandingCont {
    margin-top: 75px;
    color: white;
}

.CoderImg {
    display: flex;
    width: 90%;
    margin: auto;
}

.features {
    width: 450px;
}

.Pitching {
    padding: 20px;
    width: 60%;
    margin: auto;
}

.WhyUsCont {
    height: 500px;
    background-size: contain;
    background-position: center;
}

.WhyCont {
    position: relative;
    margin-top: 5px;
    padding-top: 65px;
    height: 700px;
}

.WhyCont::before {
    content: '';
    position: absolute;
    top: 25px;
    left: 20px;
    width: 400px;
    height: 400px;
    border-radius: 50%;
    /* background: radial-gradient(circle, rgba(0, 0, 0, 0.8), rgb(109, 158, 18),rgba(64, 69, 55, 0.194));
    box-shadow: 0 0 15px rgba(166, 255, 0, 0.685), 0 0 50px rgba(166, 255, 0, 0.8); */
    background: radial-gradient(circle, rgba(61, 255, 58, 0.345) 0%, rgba(58, 255, 97, 0.025) 70%)
}

.WhyCont::after {
    content: '';
    z-index: 10;
    position: absolute;
    bottom: -101px;
    right: -10px;
    width: 400px;
    height: 500px;
    border-radius: 50%;
    /* background: radial-gradient(circle, rgba(0, 0, 0, 0.8), rgb(109, 158, 18),rgba(64, 69, 55, 0.194));
    box-shadow: 0 0 15px rgba(166, 255, 0, 0.685), 0 0 50px rgba(166, 255, 0, 0.8); */
    background: radial-gradient(circle, rgba(61, 255, 58, 0.345) 0%, rgba(58, 255, 97, 0.025) 70%)
    
}

.TechCards {
    width: 80%;
    margin: auto;
}

.WhyChooseUsCont {
    padding-top: 80px;
    margin: auto;
    width: 70%;
}

.ReasonsCont {
    display: flex;
    margin-left: 4%;
}

.PersonImg {
    width: 600px;
}

.ListOfReasonsCont {
    width: 45%;
    height: fit-content;
    margin-top: 50px;
    border-radius: 10px;
}

.Reason1 {
    text-align: left;
    padding: 10px;
    border-radius: 10px;
    background: transparent;
    backdrop-filter: blur(10px);
    border: 1px solid #4dff4a58;
}

.Reason1 p {
    padding-left: 26px;
}

.Clients {
    display: flex;
    width: 70%;
    gap: 10px;
    margin: auto;
    margin-bottom: 60px;
}

.Clients img {
    width: 200px;
    border-radius: 10px;
}

.Brands {
    text-align: left;
}

.FooterDiv {}

.Newsletter {
    display: flex;
    gap: 20px;
    margin-top: 50px;
    height: 100px;
    width: 60%;
    margin: auto;
    border: 1px solid white;
    border-radius: 10px;
    background-image: linear-gradient(to right, rgb(36, 0, 79), rgb(21, 25, 58));
}

.Newsletter h3 {
    padding: 30px;
    width: 70%;
    text-align: left;
}

.Newsletter button {
    margin: 30px;
    font-weight: bold;
}


@media screen and (max-width: 768px) {

    .LandingContainer {
        display: flex;
        flex-direction: column;
        height: fit-content;
        width: 100%;
        justify-content: center;
        align-items: center;
        padding-top: 100px;
    }

    .LandingText {
        width: 100%;
        margin: 55px;
        padding: 5%;
        text-align: center;
    }

    .LandingText h2 {
        font-size: 35px;
        padding: 0;
        width: 100%;
    }

    .HomeMainCont {
        background-color: black;
        width: 100%;
    }

    .BelowLandingCont h1 {
        font-size: 28px;
    }

    .CoderImg {
        display: flex;
        flex-direction: column;
        height: fit-content;
        width: 100%;
    }

    .features {
        padding: 0;
        margin: 0;
    }

    .ReasonsCont {
        display: flex;
        flex-direction: column;
        height: fit-content;
        margin-bottom: 50px;
        margin-right: 10px;
    }

    .Reason1 {
        margin-bottom: 10px;
    }

    .ListOfReasonsCont {
        width: 100%;
        height: fit-content;
        margin-top: 10px;
    }

    .Clients {
        margin: 10;
        display: block;
        text-align: center;
    }

    .Brands {
        text-align: center;
        margin-bottom: 15px;
    }

    .Newsletter {
        width: 90%;
        gap: 0;
        height: fit-content;
    }

    .Newsletter h3 {
        font-size: 12px;
        font-weight: 500;
        padding: 10px;
        width: 80%;
    }

    .Newsletter button {
        margin: 15px 10px 0px 0px;
    }

    .LandingText {
        width: 90%;
        margin: 10px;
        padding: 10px;
    }

    .LandingText h3 {
        font-weight: 500;
        font-size: 20px;
        padding-top: 10px;
        text-align: center;
    }

    .LandingText h1 {
        font-size: 20px;
        padding-top: 15px;
    }

    .Underline {
        margin: auto;
        width: 145px;
    }

    .line {
        margin: auto;
    }

    .Pitching {
        width: 80%;
    }

    .PersonImg {
        width: 100%;
    }

    .features {
        width: 90%;
    }

    .LandingImg {
        width: 100%;
        order: -1;
        margin: 0 0 0 0;
    }

    .ReasonsImg {
        width: 100%;
    }

    .LandingImage {
        width: 90%;
        margin: 0;
    }

    .WhyChooseUsCont {
        width: 90%;
        margin: auto;
    }

    .WhyCont{
        width: 100%;
        height: fit-content;
        margin: 15px 0 0 0;
        padding-top: 10px;
    }
    .WhyCont p{
        margin: 10px;
    }
    .Talk {
        left: 0;
    }

    /* .WhyCont::before {
      display: none;
    } */

    .WhyCont::after {
        width: 250px;
        height: 350px;
        left: -80px;
        bottom: 0px;
      }

      .Newsletter{
        flex-direction: column;

      }

      .Newsletter h3{
        font-size: 18px;
      }

      .contact-button{
        width: 100%;
      }
      .TechCards{
        margin: auto;
        width: 90%;
      }

}
