.ConsultationModal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(81, 81, 81, 0.559);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.ConsultationModal-content {
    background: linear-gradient(109.6deg, rgb(0, 0, 0) 11.2%, rgba(19, 74, 2, 0.792) 91.1%);
    padding: 20px;
    border-radius: 25px;
    text-align: center;
    max-width: 400px;
    padding-top: 150px;
    backdrop-filter: blur(5px);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

.ConsultationModal-content h2 {
    margin-top: 20px;
}

.ConsultationModal-content p {
    margin: 15px 0;
}

.Lotte{
    width: 95%;
    height: 200px;
    position: absolute;
    top: 0;
    margin: auto;
}
.ConsultationModal-content button {
    padding: 10px 20px;
    background-color: #00000000;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    border: 1px solid #818181;
}

.ConsultationModal-content button:hover {
    background-color: #45a049;
}

@media screen and (max-width: 768px) {
    .ConsultationModal-content{
        width: 250px;
    }
}