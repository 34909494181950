
.detail-page {
    width: 95%;
    margin: 0 auto;
    padding: 20px;
    text-align: center;
    font-family: Arial, sans-serif;
}

.cover-section {
    position: relative;
    text-align: center;
    margin-bottom: 20px;
}

.CoverImgCont{
    width: 100%;
    height: 250px;
    margin-top: 35px;
    box-shadow: rgba(143, 143, 143, 0.45) 0px 25px 20px -20px;
}

.cover-image {
    width: 100%;
    height: 250px;
    object-fit: cover;
    border-radius: 10px;
}


.profile-image {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    border: 5px solid white;
    position: absolute;
    bottom: -85px;
    left: 20%;
    transform: translateX(-50%);
}

.content-section {
    margin-top: 0px;
    text-align: left;
}

.title {
    font-size: 2.5em;
    color: #ffffff;
    text-align: center;
}

.description {
    padding: 30px 0;
    width: 100%;
    margin: auto;
    border-bottom: 1px solid;
    /* display: flex; */


}

.content-subsection{
    /* display: flex; */
    width: 70%;
    margin: auto;
    gap: 20px;
}

.description h2 {
    font-size: 1.5em;
    color: #ffffff;
}

.description ul {
    list-style: none;
    padding: 0;
}

.description ul li {
    margin: 10px 0;
    font-size: 1em;
    color: #f3f3f3;
}

.form-section {
    margin: 20px 0;
}

.form-section h2 {
    font-size: 1.5em;
    color: #333;
}


@media screen and (max-width: 768px) {
    .detail-page{
        margin: 0;
        padding: 0;
        width: 100%;
        margin-bottom: 45px;
    }

    .profile-image{
        width: 100px;
        height: 100px;
        bottom: -50px;
    }

    .CoverImgCont{
        margin: 0;
    }
    .cover-image{
        height: 180px;
        padding-top: 75px;
    }
    .content-section{
        padding:40px 0;
        width: 90%;
        margin: auto;

    }

    .content-subsection{
        width: 100%;
    }

    .title{
        font-size: 2rem;
    }

    .description{
        padding: 10px;
        margin: 0;
    }

    
}