html {
  scroll-behavior: smooth;
}

.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: transparent;
    backdrop-filter: blur(10px);
    padding: 10px;
    /* border-bottom: 1px solid #ced4da; */
    position: fixed;
    z-index: 5000;
    gap: 20px;
    border-radius: 50px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1), 0 0 20px rgba(128, 128, 128, 0.3);

  }
  .NavCont{
    width: 70%;
    margin: auto;
    padding-top: 15px;
  }
  

  .navbar-left {
    display: flex;
    align-items: center;
    width: fit-content;
    height: 45px;
  }

  
  .navbar-logo {
    height: 105px;
    object-fit: cover;
    margin-right: 20px;
    margin-left: 55px;
  }
  
  .navbar-nav {
    list-style-type: none;
    /* margin: 0 20px; */
    padding: 0;
    display: flex;
    margin: 0 50px;

  }
  
  .nav-item {
    margin-right: 20px;
    position: relative;
  }
  
  .nav-link {
    text-decoration: none;
    color: white;
    font-size: 18px;
    font-weight: 500;
    transition: all 0.3s ease;
  }
  .nav-link.active {
    background-color: rgba(255, 255, 255, 0.1);
    padding: 8px 15px;
    color: #7fff1d;
    border-radius: 50px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  }
  
  .nav-link.active::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 50px;
    z-index: -1;
    transition: transform 0.3s ease-in-out;
    transform: scale(1.05);
  }
  
  .nav-link:not(.active):hover {
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 50px;
  }
  
  .navbar-right {
    display: flex;
    align-items: center;
    /* width: 100px; */
    margin: 0 50px;
  }

  
  .contact-button {
    padding: 8px 20px;
    background-color: rgb(166, 255, 0);
    color: rgb(0, 0, 0);
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }


  .contact-button:hover {
    background-color: #36b300;
  }

  .hamburger {
    display: none;
    flex-direction: column;
    cursor: pointer;
  }
  
  .bar {
    height: 3px;
    width: 25px;
    background-color: #333;
    margin: 4px 0;
    transition: 0.3s;
  }

  .whatsapp-button {
    /* border: 2px solid #36b300; */
    border-radius: 100%;
    /* background: #cfffc58e; */
    /* backdrop-filter: blur(10px); */
    padding: 4px;
    /* box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); */
    
    /* border-right: 2px solid #4dff4a; */
  }  
  
  

  @media only screen and (max-width: 768px) {
    .navbar{
      justify-content: space-between;
      width: 93%;
      padding: 5px;
      /* margin-left: 10px; */
      border-radius: 25px;
      margin: 10px;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1), 0 0 20px rgba(128, 128, 128, 0.3);
      border: 1px solid #ced4da;
    }
    .navbar-nav {
      display: none;
    }

    .NavCont{
      width: 100%;
      margin: auto;
      padding-top: 1px;
    }
    
  
    .navbar-left {
      width:fit;
      text-align: center;
      margin: 0px;
    }
  
    .navbar-right {
      width: fit;
      text-align: right;
      /* margin: 10px; */
      margin: 0 0;
    }

    .navbar-logo{
      margin: 0px;
      height: 90px;
    }

    .contact-button {
      justify-content: space-around;
    }

    .hamburger {
      flex-direction: column;
      cursor: pointer;
    }
    
    .bar {
      height: 3px;
      width: 25px;
      background-color: #333;
      margin: 4px 0;
      transition: 0.3s;
    }
    
    .whatsapp-button {
      border: none;
      border-radius: 100%;
      background: transparent;
      /* backdrop-filter: blur(10px); */
      padding: 5px;
      margin-left: 35px;
      /* box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); */
      font-size: 16px;
      /* border-right: 2px solid #4dff4a; */
  }  
  }
  
  