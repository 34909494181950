/* ConsultationForm.css */

.consultation-form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background-color: #000000;
    padding: 20px;
    border-radius: 10px;
    /* box-shadow: 0 4px 8px rgba(146, 255, 141, 0.842); */
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
    /* border:1px solid rgba(128, 255, 0, 0.525); */
}

/* .consultation-form h2 {
    align-self: center;
    margin-bottom: 20px;
    color: #e74444;
} */
 .form-section h2{
    color: #b9b9b9;
 }

.consultation-form label {
    margin-bottom: 5px;
    color: #555555;
    font-weight: bold;
}

.consultation-form input {
    width: 96.5%;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 1em;
    background-color: #000000;
}

input:focus {
    background-color: #000000; /* Light background color when focused */
    border-color: #007bff;    /* Change border color when focused */
    outline: none;            /* Remove default outline */
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5); /* Add a subtle shadow */
    transition: background-color 0.3s, border-color 0.3s; /* Smooth transition */
}

.input-filled {
    background-color: #000000; 
    border-color: #66b3ff;    
}

.input-filled:focus {
    background-color: #000000; /* Slightly darker blue when focused */
    border-color: #3399ff;     /* Darker blue border when focused */
}

.time-slots,
.days-checkboxes {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 15px;
    color: #000000;
}

.time-slot,
.day {
    padding: 10px 15px;
    border: none;
    border-radius: 20px;
    background-color: #a2a2a2;
    cursor: pointer;
    transition: background-color 0.3s;
}

.time-slot.selected,
.day.selected {
    background-color: rgb(166, 255, 0);
    color: rgb(0, 0, 0);
}

.time-slot.selected:hover,
.day.selected:hover{
    background-color: rgb(166, 255, 0);
    color: rgb(0, 0, 0);
}

.time-slot:hover,
.day:hover {
    background-color: #6277fd;
    color: white;
}

.consultation-form .submit-btn {
    align-self: center;
    padding: 10px 20px;
    font-size: 1em;
    color: rgb(0, 0, 0);
    background-color: #94ff42;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.consultation-form .submit-btn:hover {
    background-color: #0056b3;
}

.form-section h2{
    text-align: center;
}
.calendar-container {
    margin: 20px 0;
    width: 100%;
}

.available-date {
    background-color: #66b3ff !important;
    color: white !important;
}

.react-calendar__tile {
    border-radius: 100%;
    margin: 5px;
}
.react-calendar__tile--now{
    background: #8f8f91;
}
/* .react-calendar__tile:enabled:hover {
    background:rgb(166, 255, 0);
    color: #000000;
} */

.react-calendar {
    background-color: #000000;
    border-radius: 15px;
    padding: 10px;
    width: 100%;
}
/* 
.react-calendar button {
    margin: 5px;
    padding: 25px;
} */

.react-calendar__month-view__days{
    width: 100%;
    height: 350px;

}
.react-calendar__navigation button:disabled{
    background-color: #000000;

}

.react-calendar__month-view__days :disabled{
    background-color: #000000;
    color: #000000;
}

.react-calendar__tile--active {
    background:rgb(166, 255, 0) ;
}






.floating-footer {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: white;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: center;
    gap: 10px;
    align-items: center;
    padding: 10px 20px;
    z-index: 1000;
}

.price-info {
    display: flex;
    align-items: center;
    padding: 10px 20px;
    border-radius: 50px;
    background: #e7e7e7;
    width: fit-content;
}

.original-price {
    text-decoration: line-through;
    color: rgb(151, 151, 151);
    margin-right: 10px;
    white-space: nowrap;
}

.discounted-price {
    font-weight: bold;
    color: rgb(0, 0, 0);
    font-size: 20px;
    white-space: nowrap;
}

.submit-btn {
    padding: 10px 20px;
    background-color: rgb(166, 255, 0);
    color: rgb(0, 0, 0);
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-right: 55px;
    font-size: 16px;
    font-weight: 500;
    white-space: nowrap;
}

.submit-btn:hover {
    background-color: #45a049;
}


@media screen and (max-width: 768px) {
    .consultation-form{
        margin: 0;
        width: 90%;
    }

    .react-calendar__month-view__days{
        height: fit-content;
        width: fit-content;
    }

}