.HowWeDoContainer {
    padding: 2rem;
    text-align: center;
    color: #333;
    border: 1px solid #4dff4a58;
    border-radius: 10px ;
    margin-top: 30px;
  }
  
  .Content {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    position: relative;
  }
  
  .LeftSide {
    width: 30%;
    position: sticky;
    top: 2rem;
    text-align: left;
    padding-right: 15px;
    border-right: 1px solid #4dff4a58;
  }
  
  .LeftSide .Heading {
    margin-bottom: 1rem;
    cursor: pointer;
    padding: 1rem;
    border-left: 4px solid transparent;
    transition: all 0.3s ease;
  }

  .LeftSide .Heading:hover {
    background: rgba(151, 255, 24, 0.1);
    border-left: 4px solid #007bff;

    transition: all 0.3s ease;
  }
  
  .LeftSide .Heading.active {
    border-left: 4px solid #007bff;
    background: rgba(151, 255, 24, 0.1);
  }
  
  .RightSide {
    width: 65%;
    padding: 1rem;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(10px);
  }
  
  .DetailBox {
    text-align: center;
  }

  /* .DetailboxImg{
    width: 300px;
    height: 300px;
    object-fit: cover;
    text-align: center;

  } */
  
  .DetailBox img {
    width: 350px;
    height: 350px;
    object-fit: cover;
    margin-bottom: 1rem;
    border-radius: 10px;
  }
  .DetailBox p{
    font-size: 16px;
    font-weight: 400;
    padding-bottom: 10px;
  }
  
  .DetailBox h1 {
    margin-bottom: 2rem;
    font-size: 2rem;
    font-weight: 500;
  }
  
  .Content h2 {
    font-size: 16px;
    font-weight: 500;
  }
  

  @media screen and (max-width: 768px) {
    .Content {
      flex-direction: column;
      align-items: center;
    }

    .Content h2{
        font-size: 16px;
        font-weight: 400;
    }

    .HowWeDoContainer{
        padding: 5px;
    }
    .HowWeDoContainer h1{
        padding-bottom: 20px;
    }
  
    .LeftSide {
      width: 100%;
      position: relative;
      top: 0;
      padding-right: 0;
      border-right: none;
      border-bottom: 1px solid #4dff4a58;
      margin-bottom: 5px;
    }

    .LeftSide .Heading {
        margin-bottom: 1px;
        padding: 10px;
    }
  
    .RightSide {
      width: 100%;
      margin: 0px;
      padding: 0px;
    }
  
    .DetailBox img {
      width: 100%;
      height: auto;
      margin: 0px;
      
    }

    .DetailboxImg{
        margin: 0px;
    }
  }