.footer {
    /* background-color: #333; */

    color: #fff;
    padding: 40px 0;
    font-family: 'Roboto', sans-serif; /* Add custom font */
  }
  
  .footer-container {
    display: flex;
    justify-content: space-around;
    background-image: linear-gradient(to right, rgb(0, 0, 0) , rgb(1, 7, 65));

  }
  
  .footer-section {
    flex: 1;
    margin-right: 20px;
  }
  
  .logo-container {
    display: flex;
    align-items: center;
    width: 200px;
    height: 100px;
  }
  
  .logo {
    width: 200px;
    /* height: 50px; */
    margin-left: 0px;
    object-fit: cover;
  }
  
  .company-brief {
    font-size: 16px;
    margin-left: 75px;
    text-align: left;
  }
  
  .footer-section{
    margin: 50px;
  }
  .footer-section h3 {
    margin-left: 6%;
    text-align: left;
    font-size: 20px;
    margin-bottom: 15px;
    font-family: 'Montserrat', sans-serif; /* Add custom font */
  }
  
  .footer-section p {
    margin-left: 6%;
    font-size: 16px;
    text-align: left;
  }

  .footer-section i{
    width: 50px;
    height: 30px;
    /* padding: 10px; */
    border: 1px solid white;
    text-align: center;
    padding-top: 12px;
    font-size: 20px;
    border-radius: 5px;
    margin-bottom: 5px;
  }

  .newsletter {
    margin-bottom: 20px;
  }
  
  
  .newsletter input {
    padding: 10px;
    width: 200px;
  }
  
  .newsletter button {
    padding: 10px 20px;
    background-color: #fff;
    color: #333;
    border: none;
    cursor: pointer;
  }
  
  .social-icons {
    display: flex;
  }
  
  .social-icons a {
    color: #fff;
    font-size: 24px;
    margin-right: 10px;
  }
  
  .footer-bottom {
    text-align: center;
    margin-top: 20px;
    font-size: 14px;
  }
  

  @media screen and (max-width: 768px) {
    .footer-container{
      display: flex;
      flex-direction: column;
    }

    .footer-section{
      width: 90%;
    }

    .logo-container{
      padding: 0;
      width: 90%;
      margin-left: 10px;

    }

    .logo{
      margin-left: 0;
    }

    .company-brief{
      margin-left: 0;
    }

    .footer-section{
      margin: 10px;
    }
  }

  