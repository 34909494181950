.floating-menu {
    position: fixed;
    bottom: 30px;
    right: 10px;
    z-index: 1000;
  }
  
  .floating-menu-button {
    background-color: #e2e2e25e;
    color: white;
    border: none;
    border-radius: 100%;
    width: 50px;
    height: 50px;
    font-size: 25px;
    cursor: pointer;
    backdrop-filter: blur(10px);
  }
  
  .floating-menu-content {
    display: none;
    position: absolute;
    bottom: 60px;
    right: 0;
    background-color: #c1defc81;
    backdrop-filter: blur(10px);

    color: rgb(255, 255, 255);
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  .floating-menu-content.open {
    display: block;
  }
  
  .floating-menu-links {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .menu-link {
    display: block;
    padding: 10px 20px;
    text-decoration: none;
    color: #ffffff;
    font-weight: 500;
    border-bottom: 1px solid #ccc;
  }
  
  .menu-link:hover {
    background-color: #b4d795;
  }
  
 
  