/* ActionCard.css */

.action-card-container {
    display: flex;
    margin-top: 50px;
    border: 1px solid #4dff4a58;
    position: relative;
    display: inline-block;
    padding: 50px 20px;
    backdrop-filter: blur(5px);
    border-radius: 15px; /* Rounded corners for the container */
    overflow: hidden; /* Hide overflow for the neon wave effect */
    box-shadow: rgba(255, 255, 255, 0.25) 0px 50px 100px -20px, rgba(1, 63, 25, 0.3) 0px 30px 60px -30px;
    width: 70%;

}

.CTOCont{
    width: 40%;
    margin: auto;
}
.CTOImgCont{
    width: 100%;
    height: 360px;
    object-fit: cover;
}

.action-card {
    padding: 20px;
    text-align: center;
    color: white;
    z-index: 1; /* Ensure content is above the background effects */
    position: relative;
}

.SessionBtn{
    border: 1px solid #4dff4a;
    padding: 10px 20px;
    background-color: rgba(0, 0, 0, 0);
    border-radius: 5px ;
    font-weight: 500;
    font-size: 16px;
    /* color: black; */
    
}

.SessionBtn::before {
    content: "FREE";
    position: absolute;
    bottom: -45px;
    right: -30px;
    background: #ff0;
    color: #000000;
    padding: 2px 5px;
    border-radius: 3px;
    transform: rotate(-45deg);
    /* box-shadow: 0 0 10px rgba(255, 255, 0, 0.9), 0 0 20px rgba(255, 255, 0, 0.7); Neon effect */
}

.SessionBtn::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* background: linear-gradient(90deg, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0.1) 40%, rgba(255, 255, 255, 0) 60%); */
    animation: shimmer 1s infinite;
}

.title {
    font-size: 2rem;
    font-weight: 600;
    margin-bottom: 10px;
}

.subtitle {
    font-size: 1.5rem;
    margin-bottom: 10px;
}

.cta {
    font-size: 1rem;
    font-weight: bold;
    margin-top: 10px;
}

/* Neon wave effect */
.action-card-container::before {
    content: "";
    z-index: 0;
    position: absolute;
    bottom: -150px;
    right: -120px;
    width: 500px;
    height: 500px;
    border-radius: 50%;
    background: radial-gradient(circle, rgba(61, 255, 58, 0.345) 0%, rgba(58, 255, 97, 0.025) 70%);
    animation: neon-wave 3s infinite ease-in-out;
}

/* Moving neon line effect */
.action-card-container::after {
    content: "";
    position: absolute;
    background-color: rgba(0, 150, 255, 0.9);
    box-shadow: 0 0 10px rgba(0, 150, 255, 0.9), 0 0 20px rgba(0, 150, 255, 0.7);
    animation: move-line 8s linear infinite;
}



@keyframes neon-wave {
    0% {
        transform: translateY(-10%);
    }
    50% {
        transform: translateY(0%);
    }
    100% {
        transform: translateY(-10%);
    }
}

@keyframes move-line {
    0% {
        top: 0;
        left: 0;
        width: 20px; 
        height: 2px; 
    }
    25% {
        top: 0;
        left: calc(100% - 2px);
        width: 20px; 
        height: 2px; /* Full height for the right border */
    }
    50% {
        top: calc(100% - 2px);
        left: calc(100% - 2px);
        width: 20px; /* Full width for the bottom border */
        height: 20px; /* Line thickness */
    }
    75% {
        top: calc(100% - 2px);
        left: calc(100% - 2px);
        width: 2px; /* Line thickness */
        height: 20px; /* Full height for the left border */
    }
    100% {
        top: calc(100% - 2px);
        left: 0;
        width: 20px; /* Full width for the top border */
        height: 2px; /* Line thickness */
    }
}


@media screen and (max-width: 768px) {
    .action-card-container{
        width: 90%;
        margin: auto;
        padding: 0px;
        margin-top: 10px;
    }

    .CTOCont{
        width: 90%;
        margin: auto;
    }
    .action-card .title{
        font-size: 20px;
    }
    .subtitle{
        font-size: 14px;
    }
}