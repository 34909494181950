.card-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.card {
  border-radius: 15px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1), 0 0 20px rgba(128, 128, 128, 0.3);
  overflow: hidden;
  text-align: center;
  width: 400px;
  margin: 1rem;
  padding: 2rem;
  border: 1px solid #4dff4a58;
  backdrop-filter: blur(5px);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.card:hover {
  transform: translateY(-10px);
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15);
}


.card h2 {
  font-size: 1.75rem;
  margin-bottom: 1rem;
  color: #ffffff;
}

.card p {
  font-size: 1.rem;
  color: #ffffff;
  margin: 0.5rem 0;
}

.techCard {
  background: #d3d7ec3c;
  border-radius: 10px;
  padding: 1rem;
  margin: 0.5rem 0;
  transition: background 0.3s ease;
  
}


.CodingImg img {
  width: 100%;
  border-radius: 10px;
  margin-top: 1rem;
}

.techCard h1{
  font-size: 20px;
}

.techCard p{
  margin: 0px;
}
@media screen and (max-width: 768px) {
  .card-container {
    flex-direction: column;
    padding: 0px;
    margin:10px;
  }

  .card {
    margin-bottom: 10px;
    width: 100%;
    padding: 5px;
    border-radius: 10px;
  } 
  .techCard{
    margin: 15px 15px;
    padding: 5px;
    border-radius: 5px;
  }

  .card h2{
    font-size: 25px;
    padding-top: 15px;
  }

  .techCard h1{
    font-size: 18px;
    font-weight: 600;
  }
  
}
